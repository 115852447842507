
// import store from '../store';
//
// const getMenu = function () {
//     const user = store.getters.currentUser;
//     const notifications = store.getters.notifications;
//
//     const menu = [];
//     if (!user.is_staff && !user.is_auditor && !user.is_superuser && !user.is_monitor) {
//         menu.push(
//             // menu for the Branch //
//             {
//                 icon: 'fa fa-home',
//                 text: 'Branch Dashboard',
//                 link: '/branch/'
//             },
//             {
//                 icon: 'fa fa-file-excel-o',
//                 text: 'Pending details',
//                 link: '/branch/pending-details/'
//             },
//             {
//                 icon: 'fa fa-file-o',
//                 text: 'Report History',
//                 link: '/branch/report-history/'
//             },
//             {
//                 icon: 'fa fa-check',
//                 text: 'Verification',
//                 link: '/branch/verification/'
//             }
//         );
//     }
//
//     menu.push(
//         {
//             type: 'separator'
//         });
//     if (!user.is_staff && !user.is_auditor && !user.is_superuser && !user.is_monitor) {
//         menu.push(
//             // {
//             //     icon : '',
//             //     text : notifications.notifications_count,
//             //     link : '#'
//             // },
//             {
//                 icon: '',
//                 class: 'notification-btn',
//                 event: 'notifications-popup',
//                 text: '<div class="notification-contain"><i class="fa fa-commenting"></i><span class="notification-bullet ml-1 p-1 bg-secondary">' + notifications.notifications_count + '</span></div>',
//                 link: '#'
//             }
//         );
//     }
//     menu.push(
//         {
//             icon: 'fa fa-user-circle-o',
//             text: user.name,
//             dropdownPlacement: 'right',
//             link: '#',
//             items: [
//                 {
//                     text: 'Logout',
//                     event: 'logout'
//                 }
//             ]
//         }
//     );
//
//     return [
//         ...menu
//     ];
// };
// export default getMenu;

import store from '../store';

const getMenu = function () {
    const user = store.getters.currentUser;

    const menu = [
        {
            text: user.name,
            icon: 'fa fa-user',
            class: 'ml-auto',
            items: [
                {
                    text: 'Logout',
                    link: '/logout/'
                },
                {
                    text: 'Update Password',
                    link: '/update-password/'
                }
            ]
        }
    ];

    const adminMenu = [
        {
            text: 'Admin Master Data',
            link: '#',
            items: [
                {
                    text: 'Master Data',
                    link: '/app/master-data/'
                },
                {
                    text: 'Master Data',
                    link: '/app/master-data/'
                }
            ]
        }
    ];

    // Do something similar for any number of roles
    if (user && user.admin === true) {
        return [
            ...menu,
            ...adminMenu
        ];
    }
    return [
        ...menu
    ];
};
export default getMenu;
