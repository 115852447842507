import store from '../store';

const getMenu = function () {
    const user = store.getters.currentUser;

    const adminMenu = [
        {
            icon: 'fa fa-home',
            text: 'Dashboard',
            link: '/dashboard/'
        },
        {
            icon: 'fa fa-archive',
            text: 'Master Data',
            items: [
                {
                    icon: 'fa fa-spinner',
                    text: 'Breeds',
                    link: '/breeds/'
                },
                {
                    icon: 'fa fa-spinner',
                    text: 'Species',
                    link: '/species/'
                }
            ]

        },
        {
            icon: 'fa fa-cubes',
            text: 'Facilities',
            link: '/facilities/'
        },
        {
            icon: 'fa fa-star',
            text: 'Awards',
            link: '/awards/'
        },
        {
            icon: 'fa fa-address-book',
            text: 'Contact Us',
            link: '/contact-us/'
        },
        {
            icon: 'fa fa-spinner',
            text: 'Bulls',
            link: '/bulls/'
        },
        {
            icon: 'fa fa-gear',
            text: 'Settings',
            items: [
                {
                    icon: 'fa fa-film',
                    text: 'Banner',
                    link: '/settings/banners/'
                },
                {
                    icon: 'fa fa-pencil',
                    text: 'Terms & Privacy',
                    link: '/settings/terms-and-privacy/'
                }
            ]
        }
    ];

    const menu = [];

    // Do something similar for any number of roles
    if (user && user.admin === true) {
        return [
            ...menu,
            ...adminMenu
        ];
    }
    return [
        ...menu,
        ...adminMenu
    ];
};
export default getMenu;
